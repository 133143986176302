import React, { useState, useEffect, useRef, memo, useMemo, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';

import API from 'api/api';
import ICONS from 'assets/icons';
import { useToggle } from 'hooks';
import { getContactAvatar, classModifier } from 'utils';
import { CHAT_TELEGRAM_GROUP, CHAT_TYPES, CONTACT_TYPES } from 'config/constants';
import { openModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import { toggleContactPin, toggleContactMark } from 'redux/ducks/contacts';
import {
  selectUserTimezone,
  selectDefaultSession,
  selectContactsActiveGirlsFromChats,
  selectActiveGirlByDiva,
  selectActiveSessionByContactId,
} from 'redux/selectors/selectors';
import { addDivaGirlsToEntities, getActiveDivaGirls } from 'redux/ducks/divaGirls';
import {
  addTelegramGroupMembers,
  addTelegramGroupMembersPhotos,
  updateActiveGroup,
} from 'redux/ducks/girlChats';
import { createSession, updateActiveSession } from 'redux/ducks/sessions';

import './ChatHeader.scss';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import ContactTels from './ContactTels';
import ContactEmails from './ContactEmails';
import TriStateSwitch from 'components/TriStateSwitch/TriStateSwitch';
import BookingIcon from '../../../../components/BookingIcon';
import CopyToClipboardButton from 'components/CopyToClipboardButton/CopyToClipboardButton';
import ChatHeaderActionMenu from './ChatHeaderActionMenu/ChatHeaderActionMenu';
import ProfileRates from 'components/ProfileRates/ProfileRates';
import QuickBookingGirlsListItemByDiva from '../QuickBookingGirlsListItem/QuickBookingGirlsListItemByDiva';
import ChatCreatorForm from '../ChatCreatorForm/ChatCreatorForm';
import Icon from 'components/Icon/Icon';
import ContactBlockInfo from './ContactBlockInfo';
import fetchGirlContactsForBookingCreation from 'utils/fetchGirlContactsForBookingCreation';
import OverflowedTags from 'components/OverflowedTags/OverflowedTags';
import ContactTags from 'components/ContactTags/ContactTags';

const getRealAge = (birthday) => {
  if (birthday) {
    const b = birthday.split('-');
    const birthdayDate = new Date(b[0], b[1] - 1, b[2]);
    return `${Math.floor((Date.now() - birthdayDate.getTime()) / 1000 / 60 / 60 / 24 / 365)}`;
  }
}

const ChatHeader = ({
  activeRecipient,
  profile,
  getActiveDivaGirls,
  activeSession,
  defaultSession,
  createSession,
  openModal,
  activeGirl,
  updateActiveGroup,
  activeGroup,
  groupMembers,
  loadedGroupIds,
  addTelegramGroupMembers,
  addTelegramGroupMembersPhotos,
  contactSessionId,
  isSalesPageOpen,
  ...props
}) => {
  // const navigate = useNavigate();


  // const {
  //   getTooltipProps,
  //   setTooltipRef,
  //   setTriggerRef,
  //   visible,
  // } = usePopperTooltip({
  //   trigger: "click",
  //   placement: 'top',
  //   closeOnOutsideClick: true,
  //   closeOnTriggerHidden: true,
  // })

  const isTaken = activeRecipient.relatedUserId && activeRecipient.relatedUserId === props.userId;

  const [blockInfo, toggleContactInfo] = useToggle(false);
  const [isRatesOpen, toggleRatesOpen] = useToggle(false);
  // const [isNotificationActive, setIsNotificationActive] = useState(false);
  const [reviewsCount, setReviewsCount] = useState(0);

  const [telegramGroup, setTelegramGroup] = useState(null);
  const [isBookingsMembersShown, setIsBookingsMembersShown] = useState(false);
  const [isCommissionMembersShown, setIsCommissionMembersShows] = useState(false);

  
  const dispatch = useDispatch();

  const contactInfoRef = useRef();
  const msgRef = useRef();

  const {
    visible,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps,
    getArrowProps,
  } = usePopperTooltip({
    trigger: 'hover',
    placement: 'bottom',
  });

  useEffect(() => {
    contactInfoRef.current && contactInfoRef.current.scrollToTop();

    if (isGirlChat && !profile && activeRecipient.diva_id) {
      const prepIds = props.activeContactsGirlsFromChats.map(girl => girl.diva_id);
      getActiveDivaGirls({ params: { 'filter-ids': prepIds } });
    }

    if (isGirlChat) {
      setTelegramGroup(null);
      setIsBookingsMembersShown(false);
      setIsCommissionMembersShows(false);

      if (!activeRecipient.has_telegram_groups) {
        updateActiveGroup(null);
      } else {
        updateActiveGroup(0);
      }

      setTimeout(() => {
        getChatGroups();
      })

      API.getActiveDivaGirlsByContactsIds(activeRecipient.id)
        .then(res => props.addDivaGirlsToEntities([res.data]))
        .catch(console.error);
    }

    if (!isClientChat && !isGirlChat) {
      setTelegramGroup(null);
      setIsBookingsMembersShown(false);
      setIsCommissionMembersShows(false);
      updateActiveGroup(null);
    }

  }, [activeRecipient.id]);

  useEffect(() => {
    if (profile) {
      setReviewsCount(profile.reviewsCount);
    }
  }, [profile]);

  const onQuickBookingClick = () => {
    openModal(MODAL_TYPES.searchList, {
      fetchData: (props) => fetchGirlContactsForBookingCreation({ dispatch, ...props }),
      itemComponent: QuickBookingGirlsListItemByDiva,
      isHandleClickCanBeRedefinedByItem: true,
    });
  }

  const handleBookingsListOpen = () => {
    openModal(MODAL_TYPES.bookingsList, {
      contactId: activeRecipient.id,
      activeRecipient,
      onQuickBookingClick
    })
  }

  const isBlocked = !!activeRecipient.blocked;

  const onNameClick = () => {
    if (!props.fullScreenMode) {
      openModal(MODAL_TYPES.contactCard, { contact: activeRecipient.id });
    }
  };

  const getContactName = () => {
    const isEx = activeRecipient.fn?.startsWith('EX ');
    const splittedName = activeRecipient.fn?.split(' ');
    let name = activeRecipient.fn;

    if (isGirlChat) {
      // name = isEx ? splittedName[1] : splittedName[0];
      // name = `${activeRecipient.short_name} ${activeRecipient.agentName || ''}`;
      name = `${activeRecipient.headerName || ''}`;
    } else if (isEx) {
      name = splittedName.slice(1).join(' ');
    }

    if (isEx) {
      return <><span className="chat-header__ex">EX</span>{name}</>
    }

    return name;
  };

  const getContactDetails = () => {
    const getId = () => {
      if (activeRecipient.diva_id) {
        return `ID ${activeRecipient.diva_id}`
      }
    }

    if (profile) {
      const { prices, location_main, birthday } = profile;

      const pricesStr = `${prices[0].incall || '-'}/${prices[2].incall || '-'}/${prices[2].outcall || '-'}`;

      const getLocation = () => {
        if (location_main) {
          return ` - ${location_main}`;
        }

        return '';
      }

      const getId = () => {
        if (activeRecipient.diva_id) {
          return ` - ID ${activeRecipient.diva_id}`
        }

        return '';
      }

      const getAge = () => {
        const realAge = getRealAge(birthday);

        if (activeRecipient.age || realAge) {
          return ` - ${activeRecipient.age || '-'}/${realAge || '-'} y.o`;
        }

        return '';
      }

      return pricesStr + getLocation() + ` - ${getId()}` + getAge();
    }

    return getId();
  };

  const openProfileReviews = () => {
    if (!profile.reviewsCount || !activeRecipient.diva_default_id) return;

    const handleDeleteReview = () => setReviewsCount(prevState => prevState - 1);

    openModal(MODAL_TYPES.profileReviews, { id: activeRecipient.diva_default_id, handleDeleteReview });
  };

  const contactDetails = getContactDetails();
  const isGirlChat = activeRecipient.type === CONTACT_TYPES.GIRL;
  const isClientChat = activeRecipient.type === CONTACT_TYPES.CLIENT;
  const isInfoBottom = isGirlChat && (!!activeRecipient.rating || !!activeRecipient.reviewsCount || contactDetails);
  const girlBookingsInfo = props.girlBookingDetails.find(el => el.girlId === activeRecipient.id);

  const toggleSession = () => {
    if (contactSessionId) {
      API.switchSalesSession(contactSessionId);

      props.updateActiveSession(contactSessionId);

      // return setIsNotificationActive(true);
    } else {
      if (activeSession === 0) {
        createSession(
          activeRecipient.id,
          [],
          activeGirl.id || defaultSession.comparedIds,
          defaultSession.activeFilters,
          defaultSession.additionalFilters,
          defaultSession.recentlyViewedIds,
        ).then((sessionId) => {
          props.updateActiveSession(sessionId);
        })
      } else {
        createSession(activeRecipient.id).then((sessionId) => {
          props.updateActiveSession(sessionId);
        })
      }
    }

    if (!isSalesPageOpen) {
      window.open('/sales', '_blank');
    }
  }

  const portraitMedia = useMemo(() => {
    if (!profile) {
      return [];
    }

    return profile.images_info
      .filter((image) => (
        image.is_published && image.ratio === 'portrait'
      ))
      .sort((prevImage, nextImage) => Number(nextImage.is_video) - Number(prevImage.is_video))
  }, [profile])

  const onOpenGallery = useCallback(() => {
    const media = portraitMedia.length ? portraitMedia : [profile.avatar];

    openModal(MODAL_TYPES.photoGallery, {
      profile,
      state: {
        activeSlide: 0,
      },
      media
    })
  }, [portraitMedia]);

  const selectGroupChat = (groupId, groupMembersIds, groupInternalId, type) => {
    if (groupId === activeGroup) return;

    if (!loadedGroupIds.includes(groupId)) {
      const memberIds = !!groupMembersIds ? groupMembersIds : Object.keys(groupMembers);

      if (!!memberIds.length) {
        API.getChatGroupsPhotos(groupId, memberIds, type)
          .then(({ data }) => {
            addTelegramGroupMembersPhotos(groupId, data);
          })
          .catch(console.error);
      }
    }

    updateActiveGroup(groupId, groupInternalId, type);
  };

  const getChatGroups = () => {
    API.getChatGroups(activeRecipient.id)
      .then(({ data }) => {
        const groupMembers = data.map(group => group.members).flat();
        const bookingGroup = data.find((group) => group.type === 1);
        const commissionGroup = data.find((group) => group.type === 2);
        const groupMembersIds = groupMembers.map(({ user_telegram_id }) => user_telegram_id);

        if (bookingGroup) {
          selectGroupChat(bookingGroup.telegram_group_id, groupMembersIds, bookingGroup.id, bookingGroup.type);
        } else if (commissionGroup) {
          selectGroupChat(commissionGroup.telegram_group_id, groupMembersIds, commissionGroup.id, commissionGroup.type);
        }

        setTelegramGroup(data);
        addTelegramGroupMembers(groupMembers);
      })
      .catch(console.error)
  };

  const changeRelatedStatus = () => {
    const apiAction = !isTaken ? "lock" : "unlock";

    return API.lockContact(apiAction, activeRecipient.id);
  };

  const toggleMembersShown = (groupType) => {
    if(groupType === 1) {
      setIsBookingsMembersShown(!isBookingsMembersShown)
    } else {
      setIsCommissionMembersShows(!isCommissionMembersShown);
    }
  } 

  const getMembersShownStatus = (groupType) => {
    if(groupType === 1) {
      return isBookingsMembersShown
    } 

    return isCommissionMembersShown;
  }

  const isMembersVisible = (activeGroupId) => {
    const activeGroup = telegramGroup?.find((group) => group.telegram_group_id === activeGroupId);

    if(activeGroup) {
      return getMembersShownStatus(activeGroup.type);
    }

    return false;
  }

  return (
    <>
      <div
        className={classModifier("chat-header", [
          !activeRecipient.id && "hidden",
          activeRecipient.agentId && "with-agent",
          activeRecipient.availability && activeRecipient.availability
        ])}
      >
        {activeRecipient.id === "new_chat" ? (<>
          <ChatCreatorForm //TODO v2
            updateActiveContact={props.updateActiveContact}
            removeContactTab={props.removeContactTab}
          />
        </>) : (
          <>
            <div className='chat-header__header'>
              <div
                className={`chat-header__ava-wrap ${isBlocked ? "blocked" : ""}`}
                onClick={profile && onOpenGallery}
                onMouseEnter={isBlocked ? () => toggleContactInfo() : null}
                onMouseLeave={isBlocked ? () => toggleContactInfo() : null}
              >
                <LazyLoadImage
                  src={getContactAvatar(activeRecipient)}
                  className='round-img'
                  alt='contact_avatar'
                />

                {blockInfo && (
                  <ContactBlockInfo
                    activeRecipient={activeRecipient}
                    userTimezone={props.userTimezone}
                  />
                )}
              </div>

              {visible && (isGirlChat || isClientChat) &&
                <div
                  ref={setTooltipRef}
                  {...getTooltipProps()}
                  className='chat-header__fn-tooltip-wrapper'
                >
                  {activeRecipient.fn}
                  <span {...getArrowProps()}></span>
                </div>
              }

              <div className="chat-header__info">
                <div className="chat-header__info-top">
                  <div className='chat-header__contact-details'>
                    <CopyToClipboardButton
                      className='item__copy'
                      value={activeRecipient.fn}
                      disabled={!activeRecipient.fn}
                      title='Copy name'
                    />

                    {Boolean(activeRecipient.available_card) && (
                      <ICONS.card className='chat-header__card' />
                    )}

                    <p
                      ref={setTriggerRef}
                      onClick={onNameClick}
                      className='chat-header__name'
                    >
                      {getContactName()}
                    </p>
                  </div>

                  <div className='chat-header__action-buttons'>
                    {props.type === CHAT_TYPES.GIRL && !!girlBookingsInfo &&
                      <div
                        className="chat-header__bookings-block"
                      >
                        <span className="chat-header__bookings-count">{girlBookingsInfo?.bookingsCount}</span>
                        <ICONS.eye className="chat-header__booking-icon-eye" />
                      </div>
                    }
                    {props.type === CHAT_TYPES.CLIENT &&
                      <>
                        <button
                          className={classModifier('chat-header__btn', ['session', contactSessionId && 'disabled'])}
                          onClick={toggleSession}
                          title={contactSessionId ? 'switch session' : 'start session'}
                          disabled={!props.showSalesButton}
                        // ref={setTriggerRef}
                        >
                          <ICONS.puzzle className={
                            classModifier('chat-header__icon', 'session')
                          } />
                        </button>

                        {/*{visible && isNotificationActive && (*/}
                        {/*  <div*/}
                        {/*    className={classModifier('popup', 'large')}*/}
                        {/*    ref={setTooltipRef}*/}
                        {/*    {...getTooltipProps()}*/}
                        {/*  >*/}
                        {/*    There is already an active session with this client. You can*/}
                        {/*    have only one active session with same client at a time.*/}

                        {/*    <ICONS.warn className='popup__icon' />*/}
                        {/*  </div>*/}
                        {/*)}*/}
                      </>
                    }

                    {activeRecipient.type === CHAT_TYPES.CLIENT &&
                      <>
                        {/*{!activeRecipient.closestBooking && (*/}
                          <button className={classModifier('chat-header__btn', 'booking')}>
                            <ICONS.booking
                              className="chat-header__icon"
                              onClick={onQuickBookingClick}
                            />
                          </button>
                        {/*)}*/}

                        {activeRecipient.uncompletedBooking && (
                          <BookingIcon
                            className={classModifier('chat-header__btn', 'booking')}
                            booking={activeRecipient.closestBooking}
                            userTimezone={props.userTimezone}
                            onClick={handleBookingsListOpen}
                          />
                        )}
                      </>
                    }

                    {activeRecipient.type === CONTACT_TYPES.GIRL && <>
                      <div className='chat-header__available'>
                        <TriStateSwitch
                          value={activeRecipient.availability ? activeRecipient.availability : "waiting"}
                          onChange={(value) => API.changeGirlsStatus(value, activeRecipient.id)}
                        />
                      </div>

                      {!!activeRecipient.diva_default_id && profile &&
                        <button
                          className="chat-header__btn"
                          onMouseEnter={() => toggleRatesOpen(true)}
                          onMouseLeave={() => toggleRatesOpen(false)}
                        >
                          <ICONS.poundSign className="chat-header__icon" />

                          {isRatesOpen &&
                            <div className="chat-header__rates">
                              <ProfileRates
                                isEditor
                                profileId={profile?.id}
                                isMember={profile?.is_for_member || false}
                                defaultPrices={profile?.prices || []}
                                memberPrices={profile?.prices_member || []}
                                secretPrices={profile?.prices_secret || []}
                              />
                            </div>
                          }
                        </button>
                      }
                    </>}

                    {props.type === CHAT_TYPES.CLIENT &&
                      <button
                        className="chat-header__btn"
                        title={isTaken ? 'Release' : 'Take'}
                        onClick={changeRelatedStatus}
                      >
                        <Icon
                          icon={isTaken ? ICONS.handRock : ICONS.handPaper}
                          className={classModifier('chat-header__icon', isTaken && 'taken')}
                        />
                      </button>
                    }
                  </div>
                </div>

                {isInfoBottom &&
                  <div className="chat-header__info-bottom">
                    {contactDetails &&
                      <p className="chat-header__details">{contactDetails}</p>
                    }

                    {!!profile?.rating &&
                      <span className="chat-header__rating">
                        {profile.rating.rating}&nbsp;
                      </span>
                    }

                    {!!activeRecipient.diva_default_id && profile &&
                      <button
                        className="chat-header__reviews"
                        onClick={openProfileReviews}
                      >
                        ({reviewsCount})
                      </button>
                    }
                  </div>
                }
              </div>
              {/*
                <ContactDetails
                  profile={profile}
                  activeRecipient={activeRecipient} 
                /> */}

              <div className="chat-header__action-menu">
                <ChatHeaderActionMenu
                  type={props.type}
                  userId={props.userId}
                  girlDivaId={profile?.id}
                  activeRecipient={activeRecipient}
                  toggleContactPin={props.toggleContactPin}
                  toggleContactMark={props.toggleContactMark}
                  openModal={openModal}
                />
              </div>
            </div>


            <div className="chat-header__row" ref={msgRef}>
              <div className="chat-header__contact">
                <div className="chat-header__contact-tels">
                  <ContactTels
                    callFromChat={props.callFromChat}
                    activeRecipient={activeRecipient}
                  />
                </div>

                <div className="chat-header__contact-emails">
                  <ContactEmails activeRecipient={activeRecipient} />
                </div>
              </div>
            </div>

            <ContactTags
              className="chat-header__tags"
              callers={activeRecipient}
              withoutAbsentLabel
              wrapper={OverflowedTags}
              wrapperProps={{ responsive: true }}
            />

            {/* {isSessionMenuOpen && // TODO v2
                <div ref={sessionMenuRef}>
                  <DropWrapper 
                    dropWrapperRef={sessionMenuRef}
                    closeDropWrapper={toggleSessionMenu}
                  >
                    <StartSessionBtn
                      activeRecipientId={activeRecipient.id}
                      toggleSalesBtn={toggleSessionMenu}
                    />
                  </DropWrapper>
                </div>
              } */}
          </>
        )}
      </div>

      {isGirlChat &&
        <>
          <div className="chat-header__contact-groups">
            <ul className="chat-header__contact-groups-title-list">
              <li
                className={classModifier('chat-header__contact-groups-title',
                  [activeGroup === null && 'active'])}
                onClick={() => updateActiveGroup(null)}
              >
                Private
              </li>
              {telegramGroup?.map((group) => (
                <li
                  key={group.id}
                  className={classModifier('chat-header__contact-groups-title',
                    [activeGroup === group.telegram_group_id && 'active']
                  )}
                  onClick={() => selectGroupChat(group.telegram_group_id, null, group.id, group.type)}
                >
                  {CHAT_TELEGRAM_GROUP[group.type]}
                  
                  <button 
                    className={classModifier('chat-header__contact-groups-title-btn',
                      [getMembersShownStatus(group.type) && 'active']
                    )}
                    onClick={() => toggleMembersShown(group.type)}
                  >
                    <ICONS.angleQuote
                      className='chat-header__contact-groups-title-btn-icon'
                      width={11}
                      height={11}
                    />
                  </button>
                </li>
              ))}
            </ul>

            {isMembersVisible(activeGroup) && (
              <ul className="chat-header__contact-groups-name-list">
                {telegramGroup
                  ?.find((group) => group.telegram_group_id === activeGroup)
                  ?.members?.map((member) => (
                    <li
                      key={member.id}
                      className="chat-header__contact-groups-name"
                    >
                      {member.first_name || member.username}
                    </li>
                  ))}
              </ul>
            )}

          </div>
        </>
      }
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  profile:
    state.divaGirls.entities[ownProps.activeRecipient.diva_default_id]
      || state.divaGirls.entities[ownProps.activeRecipient.diva_id],
  userTimezone: selectUserTimezone(state),
  girlBookingDetails: state.clientChats.bookingDetails,
  activeSession: state.sessions.activeSession,
  defaultSession: selectDefaultSession(state),
  activeGirl: selectActiveGirlByDiva(state),
  activeGroup: state.girlChats.activeGroup,
  groupMembers: state.girlChats.groupChat.members,
  loadedGroupIds: state.girlChats.groupChat.loadedGroupIds,
  activeContactsGirlsFromChats: selectContactsActiveGirlsFromChats(state),
  contactSessionId: selectActiveSessionByContactId(state, ownProps.activeRecipient.id) || 0,
  isSalesPageOpen: state.user.isSalesPageOpen,
});

const mapDispatchToProps = {
  getActiveDivaGirls,
  openModal,
  toggleContactPin,
  toggleContactMark,
  addDivaGirlsToEntities,
  createSession,
  updateActiveGroup,
  addTelegramGroupMembers,
  addTelegramGroupMembersPhotos,
  updateActiveSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(ChatHeader));
