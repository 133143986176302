import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Scrollbars } from 'react-custom-scrollbars-2';

import API from "api/api";
import ICONS from "assets/icons";
import { useDidMount, useToggle } from "hooks";
import { closeModal } from 'redux/ducks/activeWindows';
import { deleteDivaGirlReview } from "redux/ducks/divaGirls";

import "./ProfileReviews.scss";
import Spinner from "components/UI/Spinner/Spinner";
import ProfileReview from "./components/ProfileReview";
import SearchInput from "components/SearchInput/SearchInput";


const sortOptions = [
  {label: "Most recent", value: "ctime=desc"},
  {label: "Oldest", value: "ctime=asc"},
  {label: "5 star first", value: "rating=desc"},
  {label: "1 star first", value: "rating=asc"},
];


const ProfileReviews = ({ 
  id,
  profile = {},
  className = 'profile-reviews',
  closeModal,
  isContactTab,
  ...props }) => {
  const [reviews, setReviews] = useState(null);
  const [filteredReviews, setFilteredReviews] = useState(null);
  const [query, setQuery] = useState('');
  const [pending, setPending] = useState(false);
  const [reviewsCount, setReviewsCount] = useState(0);
  const [sortType, setSortType] = useState(null);

  const [isSorted, toggleSorted] = useToggle(false);
  const [isSearched, toggleIsSearched] = useToggle(false);

  useDidMount(() => {
    setPending(true);
    sortBy(sortOptions[0], true);
  });

  useEffect(() => {
    if(profile) {
      setReviewsCount(+profile.reviewsCount);
    }
  }, [profile]);

  const currentReviews = (!isSorted && !query)
    ? reviews
    : filteredReviews;

  const sortBy = (sort, isDidMount = false) => {
    setQuery('');

    if (!sort.value) return toggleSorted(false);

    setPending(true);
    
    API.sortProfileReviews(id, sort.value)
      .then(({ data }) => {
        if (isDidMount) {
          setReviews(data);
        }
        setFilteredReviews(data);
        toggleSorted(true);
      })
      .catch(err => console.error(err))
      .finally(() => setPending(false));
  };

  const startSearch = useCallback((query) => {
    toggleSorted(false);
    setPending(true);
    
    API.searchProfileReviews(id, query)
      .then(({ data }) => {
        setFilteredReviews(data);
        toggleIsSearched(true);
      })
      .catch(err => console.error(err))
      .finally(() => {
        setPending(false);
      });
  }, [query]);

  const stopSearch = useCallback(() => {
    setQuery('');
    toggleIsSearched(false);
  }, []);

  const deleteReview = useCallback((reviewId) => {
    if (window.confirm("Remove review?")) {
      setPending(true);

      API.deleteProfileReview(reviewId)
        .then(() => {
          // if (!isSorted && !query) {
          //   setReviews((prevState) => {
          //     return prevState.filter(review => review.id != reviewId);
          //   });
          // }
          // else {
            setFilteredReviews((prevState) => {
              return prevState.filter(filteredReview => filteredReview.id != reviewId);
            });
            setReviews((prevState) => {
              return prevState.filter(review => review.id != reviewId);
            });

            setReviewsCount(prevState => prevState - 1);
            props.handleDeleteReview && props.handleDeleteReview();
          // }

          // props.deleteDivaGirlReview(id, reviewId);
        })
        .catch(err => console.error(err))
        .finally(() => setPending(false));
    }
  }, []);

  const updateReview = useCallback((updatedReview) => {
    setReviews((prevState) => {
      return prevState.map((review) => {
        if (review.id == updatedReview.id) {
          return updatedReview;
        } else {
          return review;
        }
      })
    });
  }, []);

  const handleCheckboxChange = (currSortType) => {
    if(currSortType === sortType) {
      setSortType(null);
      return;
    }

    setSortType(currSortType);
  }

  const renderFilteredReviewsList = currentReviews => (
    currentReviews
      ?.filter(item => item.is_published === (sortType === 'published'))
      ?.map((review) => (
          <ProfileReview
            profileId={id}
            review={review}
            key={review.id}
            className={className}
            isContactTab={isContactTab}
            deleteReview={deleteReview}
            updateReview={updateReview}
            highlightedText={isSearched && query}
          />
        )));

  const currentReviewsLength = sortType 
    ? currentReviews?.filter(item => item.is_published === (sortType === 'published')).length
    : currentReviews?.length;

  return (
    <div className={className}>
      <div className={`${className}__header`}>
        <div className={`${className}__checkbox-wrap`}>
          <span className={`${className}__rating`}>
            <ICONS.star className={`${className}__rating-icon`} />
            {profile.rating?.rating}
            <span className={`${className}__count`}>
              {` ( ${currentReviewsLength || 0} ) `}
            </span>
            Reviews
          </span>

          <label className={`${className}__checkbox-label`}>
            <input 
              type="checkbox" 
              className={`${className}__rating`}
              checked={sortType === 'published'}
              onChange={() => handleCheckboxChange('published')}
            />
            Published
          </label>

          <label className={`${className}__checkbox-label`}>
            <input 
              type="checkbox"
              className={`${className}__rating`}
              checked={sortType === 'unpublished'}
              onChange={() => handleCheckboxChange('unpublished')}
            />
            Unpublished
          </label>
        </div>

        <div className={`${className}__search`}>
          <SearchInput
            showClearBtn
            showSearchBtn
            query={query}
            setQuery={setQuery}
            stopSearch={stopSearch}
            startSearch={startSearch}
            placeHolder="search review..."
            inputClassName={`${className}__input`}
            inputWrapClassName={`${className}__search-wrapper`}
          />

          <span className="profile-reviews__sort">
            <Select
              onChange={sortBy}
              isSearchable={false}
              options={sortOptions}
              className="react-select"
              defaultValue={sortOptions[0]}
              classNamePrefix="react-select"
              getOptionValue={option => option.value}
              getOptionLabel={option => option.label}
            />
          </span>
        </div>

        {!isContactTab && 
          <span className={`${className}__close`} onClick={() => closeModal()}>
            <ICONS.close />(Esc)
          </span>
        }
      </div>

      <div className={`${className}__scrollbar-wrap`}>
        {pending 
          ?
            <div className={`${className}__pending`}>
              <Spinner spinnerSize="70px"/>
            </div>

          : <Scrollbars 
              renderThumbVertical={() => <div className={`${className}__scrollbar-thumb`} />}
              renderTrackVertical={() => <div className={`${className}__scrollbar-track`} />}
            >
              {sortType 
                ? renderFilteredReviewsList(currentReviews)
                : currentReviews
                    ?.map((review) => (
                      <ProfileReview
                        profileId={id}
                        review={review}
                        key={review.id}
                        className={className}
                        isContactTab={isContactTab}
                        deleteReview={deleteReview}
                        updateReview={updateReview}
                        highlightedText={isSearched && query}
                      />
                    ))
              }
            </Scrollbars>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  profile: state.divaGirls.entities[ownProps.id]
});

const mapDispatchToProps = ({
  closeModal,
  deleteDivaGirlReview,
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileReviews);
