import React, { memo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import API from 'api/api';
import { classModifier } from 'utils';
import { BUTTON_THEMES, INPUT_THEMES, PERMISSIONS } from 'config/constants';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import { isAllowed } from 'redux/selectors/selectors';
import ICONS from 'assets/icons';
import fetchGirlContactsForBookingCreation from 'utils/fetchGirlContactsForBookingCreation';

import './CalendarHeader.scss';
import Button from 'components/Button/Button';
import { BookingSearchInput, DateTimeChanger, TodayButton } from 'components/FilteredBookings';
import QuickBookingGirlsListItemByDiva from 'containers/Chat/components/QuickBookingGirlsListItem/QuickBookingGirlsListItemByDiva';
import Navbar from 'containers/Header/components/Navbar/Navbar';

const SECTIONS = [
  { title: 'Week View', href: '/calendar/week' },
  { title: 'Two Days', href: '/calendar/two-days' },
  { title: 'Timeline', href: '/calendar/timeline' },
  { title: 'Calendar', href: '/calendar/calendar' },
  { title: 'Table', href: '/calendar/table' },
];

const CalendarHeaderSecions = () => (
  <ul className="calendar-header__link-list">
    {SECTIONS.map(({ title, href }) => (
      <li key={href}>
        <NavLink
          key={href}
          to={href}
          className={({ isActive }) => classModifier('calendar-header__link', [isActive && 'active'])}
        >
          {title}
        </NavLink>
      </li>
    ))}
  </ul>
)

const CalendarHeader = ({ getters, date, getFilterProps, selectedBookings }) => {
  const {
    getDateTimeChangerProps,
    getTodayButtonProps,
    getSearchBookingsProps,
  } = getters;

  const dispatch = useDispatch();
  const location = useLocation();

  const isExportBookingAllowed = useSelector((state) => isAllowed(state, PERMISSIONS.EXPORT_BOOKINGS));

  const showExportBookingButton = location.pathname === "/calendar/table" && isExportBookingAllowed;

  const createNewBooking = () => {
    dispatch(openModal(MODAL_TYPES.searchList, {
      fetchData: (props) => fetchGirlContactsForBookingCreation({ dispatch, ...props }),
      itemComponent: QuickBookingGirlsListItemByDiva,
      isHandleClickCanBeRedefinedByItem: true,
      commonProps: { date: new Date(), ignoreSessionContact: true },
    }))
  }

  const handleExport = () => {
    const config = selectedBookings;

    API.getExportedBookingsDownloadLink({ ...getFilterProps(date[0]), ...config })
      .then(({ data }) => {
        const link = document.createElement('a');

        link.href = data;

        link.click();
      })
      .catch(console.error)
  };

  return (
    <div className="calendar-header">
      <div className="calendar-header__navbar-btn-wrap">
        <ICONS.calendar className="mail-header__mail-icon"/>
        <Navbar isBurgerMenu />
      </div>

      <div className="calendar-header__content">
        <CalendarHeaderSecions />

        <div className="calendar-header__calendar">
          <DateTimeChanger
            className="calendar-header__calendar-changer"
            withIcon
            {...getDateTimeChangerProps()}
          />

          <TodayButton
            className="calendar-header__today-btn"
            {...getTodayButtonProps()}
          />
        </div>

        <div className="calendar-header__rest">
          <BookingSearchInput
            inputClassName='calendar-header__input'
            inputWrapClassName='calendar-header__input-wrap'
            theme={INPUT_THEMES.dim}
            iconSize="small"
            placeHolder='Search by keyword'
            showSearchBtn
            showClearBtn
            {...getSearchBookingsProps()}
          />

          <Button
            theme={BUTTON_THEMES.inversion}
            className="calendar-header__new-booking-btn"
            onClick={createNewBooking}
          >
            New Booking
          </Button>

          {showExportBookingButton && <Button
            theme={BUTTON_THEMES.primary}
            title='Export'
            className="calendar-header__export-btn"
            disabled={!selectedBookings.all && !selectedBookings.includeIds.length}
            onClick={handleExport}
            icon={<ICONS.fileX />}
          >
            Export CSV
          </Button>}
        </div>
      </div>
    </div>
  );
};

export default memo(CalendarHeader);
