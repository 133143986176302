import ICONS from "assets/icons";
import LazyLoadImage from "components/LazyLoadImage/LazyLoadImage";
import EmojiPickerBtn from "containers/Chat/components/ChatMessageInput/components/EmojiPickerBtn/EmojiPickerBtn";
import VoiceMessageRecorder from "containers/Chat/components/ChatMessageInput/components/VoiceMessageRecorder";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Form, Field } from "react-final-form";
import TypingIndicator from "components/UI/TypingIndicator/TypingIndicator";
import TypingOperators from "components/UI/TypingIndicator/TypingOperators";
import MailFormAutosave from "containers/MailMain/components/MailFormAutosave/MailFormAutosave";
import FormTextTextarea from "components/FormTextTextarea/FormTextarea";
import { v4 as uuid } from "uuid";
import useSelectorToggle from "hooks/useSelectorToggle";
import { useDispatch, useSelector } from "react-redux";
import { selectMailByUsername } from "redux/selectors/selectors";
import {
  getContactAvatar,
  throttle,
  validateFormValues,
} from "utils";
import { CHAT_ACTION_MENUS, SEND_DELAY_MSG_REPLY } from "config/constants";
import API from "api/api";
import { deleteMessageDraftRequest, setMessageDraft } from "redux/ducks/mail";
import { useLocation } from "react-router-dom";
import { MAIL_REPLAYED_DATE_CONFIG } from "config/dates-сonfig";
import DateTime from "components/DateTime";

const MailReplayForm = ({
  item,
  senderName,
  isOutgoing,
  somePending,
  status,
  setStatus,
  setPending,
  pending,
  setIsReply,
  extraContent,
}) => {
  const mail = useSelector((state) => selectMailByUsername(state, item.to));
  const typingUsers = useSelector(
    (state) => state.typingOperators.mails[item.conversation_id]
  );
  const userId = useSelector((state) => state.user.id);

  const [serverErrors, setServerErrors] = useState({});
  const [attachments, setAttachments] = useState(item.draft?.attachments || []);
  const [actionMenu, setActionMenu] = useSelectorToggle(null);
  const [noMicro, setNoMicro] = useState(false);
  const [emailInputValueError, setEmailInputValueError] = useState(null);
  const [emails, setEmails] = useState([]);

  const location = useLocation();
  const locationDraft = location.state?.draft;

  useEffect(() => {
    locationDraft?.email && setEmails([locationDraft?.email]);
  }, [locationDraft?.email]);

  const dispatch = useDispatch();
  const draftId = item.draft?.id;

  useLayoutEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      // check audio stream and stop it
      .then((stream) =>
        console.log(stream.getTracks().forEach((track) => track.stop()))
      )
      // If user haven't microphone
      .catch(() => setNoMicro(true));
  }, []);

  const throttledSendTypingPing = useCallback(
    throttle(
      () => API.sendTypeInMail(item.conversation_id, mail?.id),
      3000,
      true
    ),
    [item.conversation_id, mail?.id]
  );

  const handleSubmitReplyClick = (values) => {
    setPending((prev) => ({ ...prev, sendReply: true }));

    sendEmailReply({ values, delay: SEND_DELAY_MSG_REPLY })
      .then(() => {
        setIsReply(false);
        setStatus(null);
        dispatch(deleteMessageDraftRequest(draftId));
      })
      .catch((err) => {
        setServerErrors({ reply: err.response?.data.message });
      })
      .finally(() => {
        setPending((prev) => ({ ...prev, sendReply: false }));
      });
  };

  const handleSaveDraft = ({ values, isWindowUnload }) => {
    // Returns Boolean in case of window unload
    if (isWindowUnload) {
      return sendEmailReply({ values, isWindowUnload: true });
    }

    // By default returns Promise
    return sendEmailReply({ values }).then(
      ({ data: { isForward, to, body, id } }) => {
        dispatch(
          setMessageDraft({
            msgId: item.id,
            draft: { isForward, to, body, id },
          })
        );
      }
    );
  };

  const handleDeleteDraft = () => {
    if (draftId) {
      setPending((prev) => ({ ...prev, delDraft: true }));
      dispatch(deleteMessageDraftRequest(draftId))
        .then(() => {
          setIsReply(false);
          setStatus(null);
        })
        .catch(console.error)
        .finally(() => {
          setPending((prev) => ({ ...prev, delDraft: false }));
        });
    }
    setIsReply(false);
  };

  const sendEmailReply = ({ values, delay, isWindowUnload }) => {
    const { isForward, email = "", reply = "" } = values;

    // Generate draft id on front-end
    const newDraftId = draftId || uuid();
    if (newDraftId !== draftId && !isWindowUnload) {
      dispatch(
        setMessageDraft({
          msgId: item.id,
          draft: { id: newDraftId },
        })
      );
    }

    return API.sendEmailReply({
      config: {
        from: item.to,
        replyOnId: item.id,
        isForward,
        recipient: emails?.[0],
        body: reply,
        isDraft: true,
        draftId: newDraftId,
        delay,
        userId,
      },
      isBeacon: isWindowUnload,
    });
  };

  // const handleAddAttachments = (e) => {
  //   const uploadFiles = [...e.target.files];

  //   if (!uploadFiles.length) return;

  //   e.target.value = null;

  //   setAttachments((state) => [
  //     ...state,
  //     ...uploadFiles.map(({ name, size }) => ({
  //       fileName: name,
  //       fileSize: size,
  //       loading: true,
  //     })),
  //   ]);
  //   setPending((prev) => ({ ...prev, attach: true }));

  //   // Generate draft id on front-end
  //   const newDraftId = draftId || uuid();

  //   API.uploadMailAttachments({ uploadFiles, draftId: newDraftId })
  //     .then(({ data: { attachments } }) => {
  //       setAttachments(attachments.map((att) => ({ ...att, loading: false })));
  //     })
  //     .catch((err) => {
  //       setAttachments((prev) => prev.filter(({ loading }) => !loading));
  //     })
  //     .finally(() => {
  //       setPending((prev) => ({ ...prev, attach: false }));
  //     });
  // };

  // const handleDeleteAttachment = (delId) => {
  //   setPending((prev) => ({ ...prev, attach: true }));

  //   API.deleteMailAttachment({ delId })
  //     .then(({ data: { attachments } }) => {
  //       setAttachments(attachments.map((att) => ({ ...att, loading: false })));
  //     })
  //     .finally(() => {
  //       setPending((prev) => ({ ...prev, attach: false }));
  //     });
  // };

  const updateVoiceMsg = (blob, duration) => {
    if (blob && duration) {
      // add voiceMsg
      setAttachments((state) => [
        ...state,
        {
          blob,
          url: URL.createObjectURL(blob),
          duration,
        },
      ]);
    }
  };

  const renderMessage = useMemo(() => {
    return (
      <div className="mail-chat-item-reply__block">
        <p className="mail-chat-item-reply__mail-text">
          ---------- Reply message ----------
        </p>
        <div className="mail-chat-item-reply__mail-info">
          <span className="mail-chat-item-reply__mail-text">
            From: {item.from}
          </span>
          <span className="mail-chat-item-reply__mail-text">
            Date:&nbsp;
            <DateTime
              className="mail-chat-item-reply__mail-text"
              date={item.created_at}
              config={MAIL_REPLAYED_DATE_CONFIG}
            />
          </span>
          <span className="mail-chat-item-reply__mail-text">
            Subject: {item.subject}
          </span>
          <span className="mail-chat-item-reply__mail-text">To: {item.to}</span>
        </div>
        <br />
        {extraContent}
      </div>
    );
  }, [extraContent, mail]);

  return (
    <Form
      initialValues={{
        isForward: item.draft?.isForward,
        reply: item.draft?.body || "",
      }}
      initialValuesEqual={() => true}
      validate={(values) => validate({ ...values, emails })}
      onSubmit={handleSubmitReplyClick}
      mutators={{
        handleChangeMessage: (...rest) => {
          const [args, state, utils] = rest;

          utils.changeValue(state, "reply", () => args[0].target.value);
        },

        handleAddEmoji: (...rest) => {
          const [args, state, utils] = rest;

          const sym = args[0].unified.split("-");
          const codesArray = [];

          sym.forEach((el) => codesArray.push("0x" + el));
          const emojiPic = String.fromCodePoint(...codesArray);

          utils.changeValue(state, "reply", (ve) => {
            return ve + emojiPic;
          });
        },
      }}
    >
      {({ handleSubmit, form }) => (
        <form className="mail-chat-item-reply__form" onSubmit={handleSubmit}>
          <div className="mail-chat-item-reply mail-chat-item-reply--is-reply">
            <div className="mail-chat-item-reply__header">
              <div className="mail-chat-item-reply__reply-icon-wrap">
                <ICONS.reply />
              </div>
              <div className="mail-chat-item__avatar-wrap">
                <div className="mail-chat-item__avatar">
                  <LazyLoadImage
                    src={getContactAvatar(isOutgoing ? item.user : item.caller)}
                  />
                </div>
              </div>
              <div className="mail-chat-item__name-box">
                <div className="mail-chat-item__name" title={senderName}>
                  {senderName}
                </div>

                <div className="mail-chat-item__email">from: {item.from}</div>
                <div className="mail-chat-item__email">to: {item.to}</div>
              </div>
            </div>
            {/* {!!attachments.length && (
                <ul className="mail-chat-item-reply__attachment-list">
                  {attachments.map(
                    ({ id, fileName, loading, extension, url }, idx) => (
                      <MailAttachment
                        url={url}
                        key={idx}
                        fileName={fileName}
                        fileExtension={extension}
                        loading={loading}
                        onDelete={() => handleDeleteAttachment(id)}
                        disableDelete={somePending}
                      />
                    )
                  )}
                </ul>
              )} */}

            <div className="mail-chat-item-reply__textarea-wrap">
              <Field
                name="reply"
                errorClass="input-error-text"
                className="mail-chat-item-reply__textarea"
                autosize
                serverErrors={serverErrors}
                component={FormTextTextarea}
                onAdditionalChange={throttledSendTypingPing}
                autoFocus
                onChange={form.mutators.handleChangeMessage}
                extraContent={renderMessage}
              />

              {actionMenu === CHAT_ACTION_MENUS.VOICE_RECORDER && (
                <VoiceMessageRecorder
                  updateVoiceMsg={updateVoiceMsg}
                  activeRecipient={item.caller}
                  // chatType={props.type}
                  // disabled={!!images?.length || !!videos?.length || !!voiceMsg}
                  // handleSubmit={handleSubmit}
                  handleSubmit={(e) => {
                    e?.preventDefault();
                  }}
                  setActive={() =>
                    setActionMenu(CHAT_ACTION_MENUS.VOICE_RECORDER)
                  }
                />
              )}
            </div>

            <div className="mail-chat-item-reply__actions">
              <div className="mail-chat-item-reply__actions-menu">
                {/* <button
                    onClick={(e) => {
                      e?.preventDefault();
                      !noMicro &&
                      setActionMenu(CHAT_ACTION_MENUS.VOICE_RECORDER)
                    }}
                    className="chat-input__btn"
                    title="voice recorder"
                  >
                    <ICONS.microphone
                      className={classModifier('chat-input__btn-icon', [
                        actionMenu === CHAT_ACTION_MENUS.VOICE_RECORDER &&
                          'selected',
                      ])}
                    />
                  </button> */}

                {/* <label className="chat-input__btn" title="attach file">
                    <ICONS.clip className="chat-input__btn-icon" />

                    <input
                      className="visually-hidden"
                      type="file"
                      multiple
                      onChange={handleAddAttachments}
                      disabled={somePending}
                    />
                  </label> */}

                <label title="emoji">
                  <EmojiPickerBtn
                    name="emoji"
                    onSelect={(emoji) => form.mutators.handleAddEmoji(emoji)}
                    isShow={actionMenu === CHAT_ACTION_MENUS.SMILES}
                    onClick={(e) => {
                      e?.preventDefault();
                      setActionMenu(CHAT_ACTION_MENUS.SMILES);
                    }}
                  />
                </label>

                {/* <MessageTemplates
                        msgTemplates={""}
                        addBoilerplate={()=>{}}
                        isShow={false}
                        isShow={actionMenu === CHAT_ACTION_MENUS.TEMPLATES}
                        onClick={() => setActionMenu(CHAT_ACTION_MENUS.TEMPLATES)}
                      /> */}
              </div>

              {typingUsers && (
                <div className="chat-input__indicator-wrap">
                  <TypingOperators operatorsIds={typingUsers} />
                  <TypingIndicator />
                </div>
              )}
            </div>
          </div>
          <div className="mail-chat-item__actions">
            <button
              className="mail-chat-item-reply__send-btn"
              type="submit"
              disabled={somePending || emailInputValueError}
            >
              SEND
              <ICONS.arrow className="mail-chat-item-reply__send-btn-icon" />
            </button>

            <button
              className="mail-chat-item__action-btn"
              type="button"
              title="Delete Draft"
              disabled={somePending}
              onClick={handleDeleteDraft}
            >
              <ICONS.trash />
            </button>
          </div>
          <MailFormAutosave
            isHide
            status={status}
            onSave={handleSaveDraft}
            setStatus={setStatus}
            disabled={pending.sendReply || pending.delDraft}
          />
        </form>
      )}
    </Form>
  );
};

const validate = ({ reply, emails }) => {
  const errors = {};

  if (validateFormValues.isEmpty(reply)) {
    errors.reply = "Enter the message";
  }

  if (!emails?.length) {
    errors.email = "Incorrect email";
  }

  return errors;
};

export default MailReplayForm;
