import React from 'react';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { Scrollbars } from "react-custom-scrollbars-2";
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import { v4 as uuid } from 'uuid';
import axios from 'axios';

import { classModifier } from 'utils';
import { useCancelToken, useDidMount, useDidUpdate } from 'hooks';
import { selectModalsTransitionModes } from 'redux/selectors/selectors';
import { SEARCH_LIST_THEMES } from 'config/constants';
import { MODAL_TYPES } from 'redux/ducks/activeWindows';

import './SearchList.scss';
import Spinner from 'components/UI/Spinner/Spinner';
import DebounceInput from 'components/DebounceInput/DebounceInput';
import SearchListContactItem from './components/SearchListContactItem/SearchListContactItem';
import ExtraItemsForSending from './components/ExtraItemsForSending/ExtraItemsForSending';

const SearchList = ({
  theme = SEARCH_LIST_THEMES.modal,
  initialQuery = '',
  fetchData,
  itemComponent: ItemComponent = SearchListContactItem,
  itemKey = 'id',
  onChoose = {},
  isInitialData = true,
  inputLabel = 'Search',
  listLabel,
  inputPlaceholder,
  multipleMode,
  filterList = () => false,
  filterField = 'id',
  choosenItems = [],
  itemProps = {},
  searchListExtraGroups,
  setSearchListExtraGroups,
  isHandleClickCanBeRedefinedByItem,
  limit,
  isModal,
  isMergeList
}) => {
  const modalsTransitionModes = useSelector(selectModalsTransitionModes);

  const [query, setQuery] = useState(initialQuery);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [searchListState, setSearchListState] = useState({
    firstPagePending: isInitialData,
    items: [],
    hasMore: false,
    part: 1,
  })

  const itemsLength = searchListState.items?.length;
  const filterItems = selectedItems ? selectedItems.map(item => item[filterField]) : [];

  // Hooks

  const isSpinnerVisible = searchListState.firstPagePending && (isInitialData || (!isInitialData && query))
  const isNoItems = !searchListState.firstPagePending && !itemsLength;
  const isNoSearch = (isNoItems || !isInitialData) && !query;
  const isNoResults = isNoItems && query;
  const isSearchListVisible = (
    !searchListState.firstPagePending
      && itemsLength > 0
      && selectedItems?.length !== searchListState.items.length
      && (isInitialData || (!isInitialData && query))
  )

  const inputId = useMemo(() => uuid(), []);
  const scrollbarsViewId = useMemo(() => uuid(), []);
  const choosenGroups = useMemo(() => (
    _.keys(_.pickBy(searchListExtraGroups, Boolean)) || []
  ), [searchListExtraGroups]);

  const { newCancelToken, cancelPrevRequest } = useCancelToken();

  useDidMount(() => {
    if (isInitialData) {
      fetchFirstPage();
    } 
  })

  useDidUpdate(() => {
    const listItemsLength = searchListState.items.length;
    
    if (searchListState.hasMore && listItemsLength - selectedItems.length <= 10) {
      fetchNextPage();
    }
  }, [searchListState.items.length, selectedItems.length])

  useDidUpdate(() => {
    setSelectedItems(choosenItems);
  }, [choosenItems.length])

  useDidUpdate(() => {
    cancelPrevRequest();

    if (query || isInitialData || choosenGroups.length) {
      fetchFirstPage();
    } else {
      setNoItems();
    }
  }, [query, choosenGroups]);

  useDidUpdate(() => {
    multipleMode 
      ? onChoose(selectedItems) 
      : onChoose(selectedItems[isMergeList ? selectedItems.length - 1 : 0]);
  }, [selectedItems, multipleMode])

  // Main functions

  const fetchFirstPage = () => {
    setIsLoading(true);

    setSearchListState((prev) => ({
      ...prev,
      firstPagePending: true,
    }))

    return fetchData({
      offset: 0,
      query,
      cancelToken: newCancelToken(),
    })
      .then(({ newItems = [], newHasMore }) => {
        setSearchListState((prev) => ({
          items: newItems || [],
          hasMore: newHasMore,
          firstPagePending: false,
          part: ++prev.part
        }))
      })
      .catch(error => {
        // if (!axios.isCancel(error)) {
        //   setNoItems();
        // }
      })
      .finally(() => setIsLoading(false));
  }

  const fetchNextPage = () => {
    setIsLoading(true);

    return fetchData({
      offset: limit ? (searchListState.part - 1) * limit : itemsLength,
      query,
      cancelToken: newCancelToken(),
      part: searchListState.part
    })
      .then(({ newItems, newHasMore }) => {
        setSearchListState((prev) => ({
          ...prev,
          items: [...prev.items, ...newItems],
          hasMore: newHasMore,
          part: prev.part + 1,
        }))
      })
      .catch(error => {
        if (!axios.isCancel(error)) {   // optional condition
          setSearchListState((prev) => ({
            ...prev,
            hasMore: false,
          }))
        }
      })
      .finally(() => setIsLoading(false));
  }

  // Helper functions

  const setNoItems = () => {
    setSearchListState((prev) => ({
      ...prev,
      items: [],
      hasMore: false,
      firstPagePending: false,
    }))
  }

  // Calculated props

  const scrollbarsRenderProps = {
    renderView: props => <div {...props} id={scrollbarsViewId} />,
    renderTrackVertical: props => <div {...props} className="track-vertical" />,
    renderThumbVertical: props => <div {...props} className="thumb-vertical" />,
  }

  const bottomSpinner = (
    <Spinner
      spinnerSize={36}
      className='search-list__bottom-spinner'
    />
  );

  const handleClick = (item) => setSelectedItems((prev) => ([...prev, item]));

  const classes = classNames(
    classModifier('search-list', SEARCH_LIST_THEMES[theme]),
    isModal && classModifier('modal', [
      modalsTransitionModes[MODAL_TYPES.searchList],
      MODAL_TYPES.searchList
    ]),
  )

  return (
    <div className={classes}>
      <div className='search-list__header'>
        {inputLabel &&
          <label className='search-list__input-label' htmlFor={inputId}>
            {inputLabel}
          </label>
        }

        <DebounceInput
          autoFocus
          id={inputId}
          initialQuery={query}
          onDebounce={setQuery}
          placeholder={inputPlaceholder}
        />
      </div>

      {listLabel &&
        <div className='search-list__list-label'>
          {listLabel}
        </div>
      }

      {searchListExtraGroups &&
        <ExtraItemsForSending
          searchListExtraGroups={searchListExtraGroups}
          setSearchListExtraGroups={setSearchListExtraGroups}
        />
      }

      <div className={classModifier('search-list__main-container', [
        (searchListState.firstPagePending || isNoSearch || isNoResults) && 'centered',
      ])}>
        {isSpinnerVisible &&
          <Spinner spinnerSize={36} />
        }
        {isNoSearch &&
          <p className='search-list__no-items'>Enter search...</p>
        }
        {isNoResults &&
          <p className='search-list__no-items'>No results</p>
        }

        {isSearchListVisible &&
          <Scrollbars
            className={classModifier('scrollbar', isLoading && 'loading')}
            autoHide
            {...scrollbarsRenderProps}
          >
            <InfiniteScroll
              className='search-list__infinite-scroll'
              scrollableTarget={scrollbarsViewId}
              dataLength={itemsLength}
              next={fetchNextPage}
              hasMore={searchListState.hasMore}
              loader={bottomSpinner}
            >
              {searchListState.items
                .filter((item) => isMergeList ? true : !(filterItems.includes(item[filterField]) || filterList(item)))
                .map(item => (
                  <ItemComponent
                    className={classModifier("search-list__item", multipleMode && 'multiple')}
                    key={item[itemKey] + uuid()}
                    item={item}
                    query={query}
                    {...(isHandleClickCanBeRedefinedByItem ? {} : { onClick: handleClick })}
                    {...itemProps}
                  />
                ))
              }
            </InfiniteScroll>
          </Scrollbars>
        }
      </div>
    </div>
  )
}

SearchList.ExtraCheckboxItems = ExtraItemsForSending;

export default SearchList;
