import ICONS from "assets/icons";
import EmojiPickerBtn from "containers/Chat/components/ChatMessageInput/components/EmojiPickerBtn/EmojiPickerBtn";
import React, { useCallback, useMemo, useState } from "react";
import { Form, Field } from "react-final-form";
import TypingIndicator from "components/UI/TypingIndicator/TypingIndicator";
import TypingOperators from "components/UI/TypingIndicator/TypingOperators";
import FormTextTextarea from "components/FormTextTextarea/FormTextarea";
import useSelectorToggle from "hooks/useSelectorToggle";
import { useSelector } from "react-redux";
import {
  selectMailByUsername,
  selectUserTimezone,
} from "redux/selectors/selectors";
import { getDateByTimezoneOffset, throttle, validateFormValues } from "utils";
import { CHAT_ACTION_MENUS, SEND_DELAY_MSG_REPLY } from "config/constants";
import API from "api/api";
import MailComposerEmailField from "../../../../MailComposer/components/MailComposerEmailFiled";
import { MAIL_REPLAYED_DATE_CONFIG } from "config/dates-сonfig";
import ReactDOMServer from "react-dom/server";
import moment from "moment";

const MailForwardForm = ({
  item,
  somePending,
  setPending,
  extraContent,
  setIsForward,
}) => {
  const mail = useSelector((state) => selectMailByUsername(state, item.to));
  const typingUsers = useSelector(
    (state) => state.typingOperators.mails[item.conversation_id]
  );
  const userId = useSelector((state) => state.user.id);
  const getEmailById = useSelector((state) =>
    state.mail.availableMails.find((mail) => mail.id === item.email_id)
  );
  const userTimezone = useSelector(selectUserTimezone);
  const hour12 = useSelector((state) => state.user.hour12);

  const convertConfig = (config) => {
    let convertedConfig = config;

    if (!hour12 && convertedConfig) {
      convertedConfig = convertedConfig
        .split("")
        .map((letter) => (letter === "h" ? "H" : letter))
        .join("");

      convertedConfig = convertedConfig
        .split(" ")
        .filter((part) => part !== "A")
        .join(" ");
    }

    return convertedConfig;
  };

  const pickedDateByTimezone = getDateByTimezoneOffset(
    userTimezone,
    item.created_at
  );

  const dateForRender = moment(pickedDateByTimezone).format(
    convertConfig(MAIL_REPLAYED_DATE_CONFIG)
  );

  const [serverErrors, setServerErrors] = useState({});
  const [actionMenu, setActionMenu] = useSelectorToggle(null);
  const [emailInputValueError, setEmailInputValueError] = useState(null);
  const [emails, setEmails] = useState([]);

  const throttledSendTypingPing = useCallback(
    throttle(
      () => API.sendTypeInMail(item.conversation_id, mail?.id),
      3000,
      true
    ),
    [item.conversation_id, mail?.id]
  );

  const handleSubmitForwardClick = (values) => {
    setPending((prev) => ({ ...prev, sendReply: true }));

    sendEmailForward({ values, delay: SEND_DELAY_MSG_REPLY })
      .then(() => {
        setIsForward(false);
      })
      .catch((err) => {
        setServerErrors({ reply: err.response?.data.message });
      })
      .finally(() => {
        setPending((prev) => ({ ...prev, sendReply: false }));
      });
  };

  const renderMessage = useMemo(() => {
    return (
      <div style={{ marginTop: "20px" }}>
        <p style={{ marginBottom: "10px" }}>
          ---------- Forwarded message ----------
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ marginBottom: "10px" }}>From: {item.from}</span>
          <span style={{ marginBottom: "10px" }}>
            Date:&nbsp;
            <time dateTime={pickedDateByTimezone}>{dateForRender}</time>
          </span>
          <span style={{ marginBottom: "10px" }}>Subject: {item.subject}</span>
          <span style={{ marginBottom: "10px" }}>To: {item.to}</span>
        </div>
        <br />
        {extraContent}
      </div>
    );
  }, [extraContent, mail]);

  const renderMessageString = ReactDOMServer.renderToString(renderMessage);

  const sendEmailForward = async ({ values }) => {
    const { message = "" } = values;
    const messageWithForwardedMessage = `<p>${message}</p>\n\n${renderMessageString}`;

    try {
      await API.sendEmailForward({
        config: {
          from: getEmailById.operatorName,
          to: emails[0],
          body: message,
          html: messageWithForwardedMessage,
          userId,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Form
      initialValues={{
        message: "",
      }}
      initialValuesEqual={() => true}
      validate={(values) => validate({ ...values, emails })}
      onSubmit={handleSubmitForwardClick}
      mutators={{
        handleChangeMessage: (...rest) => {
          const [args, state, utils] = rest;

          utils.changeValue(state, "message", () => args[0].target.value);
        },

        handleAddEmoji: (...rest) => {
          const [args, state, utils] = rest;

          const sym = args[0].unified.split("-");
          const codesArray = [];

          sym.forEach((el) => codesArray.push("0x" + el));
          const emojiPic = String.fromCodePoint(...codesArray);

          utils.changeValue(state, "message", (ve) => {
            return ve + emojiPic;
          });
        },
      }}
    >
      {({ handleSubmit, form }) => (
        <form className="mail-chat-item-reply__form" onSubmit={handleSubmit}>
          <div className="mail-chat-item-reply mail-chat-item-reply--is-forward">
            <div className="mail-chat-item-reply__header mail-chat-item-reply__header--email-field">
              <div className="mail-chat-item-reply__reply-icon-wrap">
                <ICONS.forward />
              </div>
              <Field name="emails">
                {({ meta }) => (
                  <MailComposerEmailField
                    name="emails"
                    meta={meta}
                    emails={emails}
                    setEmails={setEmails}
                    serverErrors={serverErrors}
                    emailInputValueError={emailInputValueError}
                    setEmailInputValueError={setEmailInputValueError}
                    isNeedLabel={false}
                  />
                )}
              </Field>
            </div>

            <div className="mail-chat-item-reply__textarea-wrap">
              <Field
                name="message"
                errorClass="input-error-text"
                className="mail-chat-item-reply__textarea"
                autosize
                serverErrors={serverErrors}
                component={FormTextTextarea}
                onAdditionalChange={throttledSendTypingPing}
                autoFocus
                onChange={form.mutators.handleChangeMessage}
                extraContent={renderMessage}
              />
            </div>

            <div className="mail-chat-item-reply__actions">
              <div className="mail-chat-item-reply__actions-menu">
                <label title="emoji">
                  <EmojiPickerBtn
                    name="emoji"
                    onSelect={(emoji) => form.mutators.handleAddEmoji(emoji)}
                    isShow={actionMenu === CHAT_ACTION_MENUS.SMILES}
                    onClick={(e) => {
                      e?.preventDefault();
                      setActionMenu(CHAT_ACTION_MENUS.SMILES);
                    }}
                  />
                </label>
              </div>

              {typingUsers && (
                <div className="chat-input__indicator-wrap">
                  <TypingOperators operatorsIds={typingUsers} />
                  <TypingIndicator />
                </div>
              )}
            </div>
          </div>
          <div className="mail-chat-item__actions">
            <button
              className="mail-chat-item-reply__send-btn"
              type="submit"
              disabled={somePending || emailInputValueError}
            >
              SEND
              <ICONS.arrow className="mail-chat-item-reply__send-btn-icon" />
            </button>

            <button
              className="mail-chat-item__action-btn"
              type="button"
              title="Delete Draft"
              disabled={somePending}
              onClick={() => setIsForward(false)}
            >
              <ICONS.trash />
            </button>
          </div>
        </form>
      )}
    </Form>
  );
};

const validate = ({ message, emails }) => {
  const errors = {};

  if (validateFormValues.isEmpty(message)) {
    errors.message = "Enter the message";
  }

  if (!emails?.length) {
    errors.emails = "Incorrect email";
  }

  return errors;
};

export default MailForwardForm;
